import * as React from "react";
import "./orchestry-admin-tool-api-throttling.scss";
import { Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Portlet, PortletBody } from "app/components/common/portlet";
import axios from "axios";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import * as TenantListStore from "app/store/tenants/list";
import FormButton from "app/components/common/form-button";
import Chart from "react-apexcharts";
var OrchestryAdminToolApiThrottlingHours = React.memo(function () {
    var dispatch = useDispatch();
    var _a = React.useState(null), data = _a[0], setData = _a[1];
    var _b = React.useState(null), waitData = _b[0], setWaitData = _b[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _c = React.useState(null), servers = _c[0], setServers = _c[1];
    var _d = React.useState(document.defaultThrottleServer), selectedServer = _d[0], setSelectedServer = _d[1];
    var _e = React.useState(false), isLoading = _e[0], setIsLoading = _e[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
        getData();
        getServers();
    }, []);
    React.useEffect(function () {
        // let timerID: NodeJS.Timeout | null; //= setInterval(timer, 5000); //every 5 seconds
        // timerID = setInterval(() => {
        //    getData(300);
        // }, 10000);
        // return function cleanup() {
        //    //When we leave component we stop the timer
        //    if (timerID !== null) {
        //       clearInterval(timerID);
        //    }
        // };
        getData(300);
    }, [selectedServer]);
    var getData = function (top, tenantGuid) {
        if (top === void 0) { top = 300; }
        // setData([]);
        setIsLoading(true);
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Throttling/ApiTimelineHours?top=" + top + (selectedServer !== undefined ? "&serverUrl=" + selectedServer : "");
        // axiosConfig.url = "/api/Throttling/?tenantGuid=";
        axiosConfig.responseType = "json";
        axios(axiosConfig)
            .then(function (response) {
            formatData("Data", response.data);
            formatData("Wait", response.data);
            // formatData("Data", testData as ThrottleSecond[]);
            // formatData("Wait", testData as ThrottleSecond[]);
            setIsLoading(false);
        })
            .catch(function (response) { });
    };
    var getServers = function () {
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Throttling/Servers";
        // axiosConfig.url = "/api/Throttling/?tenantGuid=";
        axiosConfig.responseType = "json";
        axios(axiosConfig)
            .then(function (response) {
            setServers(response.data);
            if (selectedServer === undefined || selectedServer === "") {
                setSelectedServer(response.data[0]);
                document.defaultThrottleServer = response.data[0];
            }
        })
            .catch(function (response) { });
    };
    var formatDataByType = function (details, app, callType) {
        var returnCount = 0;
        var foundCount = details.find(function (sc) { return sc.App === app && sc.CallType === callType; });
        if (foundCount !== undefined) {
            returnCount = foundCount.Count;
        }
        return returnCount;
    };
    var formatData = function (type, newData) {
        var dataArray = [];
        // let TotalAppCalls: DataGroup = {
        //    id: "App Total",
        //    type: 'column',
        //    name: "App Total",
        //    color: "#7E66B0",
        //    data: []
        // };
        // let TotalTenantCalls: DataGroup = {
        //    id: "Tenant Total",
        //    type: 'column',
        //    name: "Tenant Total",
        //    color: "#7E66B0",
        //    data: []
        // };
        var TenantServiceCallTypeTeamChannelMessage = {
            id: "Team Channel Messages",
            name: "Team Channel Messages",
            color: "#7E66B0",
            data: []
        };
        var TenantServiceCatchAll = {
            id: "Catch All",
            name: "Tenant Catch All",
            color: "#7E66B0",
            data: []
        };
        var TenantServiceGroupGet = {
            id: "Group Get",
            name: "Tenant Group Get",
            color: "#7E66B0",
            data: []
        };
        var TenantCosmosDb = {
            name: "Cosmos Db",
            color: "#7b83eb",
            data: []
        };
        var TenantSharePoint = {
            name: "SharePoint",
            color: "#7b83eb",
            data: []
        };
        var TenantSharePointAdmin = {
            name: "SharePoint Admin",
            color: "#b75301",
            data: []
        };
        var TenantServiceOutlookService = {
            id: "Outlook Service",
            name: "Tenant Outlook Service",
            color: "#A3B4BC",
            data: []
        };
        var TenantServiceTeamGet = {
            id: "Team Get",
            name: "Tenant Team Get",
            color: "#94BF2D",
            data: []
        };
        var TenantServicePlannerGet = {
            id: "Planner Get",
            name: "Tenant Planner Get",
            color: "#94BF2D",
            data: []
        };
        var AppOrchestryCallTypeTeamChannelMessage = {
            id: "App Channel Message",
            name: "App Channel Message",
            color: "#007989",
            data: []
        };
        var TenantServiceUserGet = {
            id: "User Get",
            name: "Tenant User Get",
            color: "#007989",
            data: []
        };
        var TenantServiceUserMemberGet = {
            id: "User Membership Get",
            name: "Tenant User Membership Get",
            color: "#007989",
            data: []
        };
        newData.forEach(function (element) {
            if (element !== undefined) {
                var DetailsArray = type === "Data" ? element.SuccessfulCounts : element.WaitedCounts;
                TenantServiceCallTypeTeamChannelMessage.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceGraph", "TeamChannelMessage") });
                TenantServiceCatchAll.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceGraph", "CatchAll") });
                TenantServiceGroupGet.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceGraph", "GroupGet") });
                TenantServiceOutlookService.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceGraph", "OutlookService") });
                TenantServiceTeamGet.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceGraph", "TeamGet") });
                // TenantCosmosDb.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "CosmosDb", "CatchAll") });
                TenantSharePoint.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceSharePoint", "SharePointCatchAll") });
                TenantSharePointAdmin.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceSharePoint", "SharePointAdminCatchAll") });
                TenantServiceUserGet.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceSharePoint", "UserGet") });
                TenantServiceUserMemberGet.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceSharePoint", "UserGetMemberships") });
                TenantServicePlannerGet.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "ServiceGraph", "PlannerGet") });
            }
        });
        dataArray.push(TenantServiceCallTypeTeamChannelMessage);
        dataArray.push(TenantServiceCatchAll);
        dataArray.push(TenantServiceGroupGet);
        dataArray.push(TenantServiceUserGet);
        dataArray.push(TenantServiceUserMemberGet);
        dataArray.push(TenantServiceOutlookService);
        dataArray.push(TenantServiceTeamGet);
        dataArray.push(TenantSharePoint);
        dataArray.push(TenantSharePointAdmin);
        dataArray.push(TenantServicePlannerGet);
        console.log(type, dataArray);
        if (type === "Data") {
            setData(dataArray);
        }
        else {
            setWaitData(dataArray);
        }
    };
    return (React.createElement("div", { className: "orchestry-admin-tool-api-throttling fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 5 },
                React.createElement(Form.Group, { className: "form-group" },
                    React.createElement(Form.Label, null, "Select Server"),
                    servers === undefined || servers === null || servers.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, value: selectedServer, list: servers.map(function (s) { return ({
                            id: s,
                            value: s,
                            title: s
                        }); }), onChange: function (serverUrl) {
                            setSelectedServer(serverUrl);
                            document.defaultThrottleServer = serverUrl;
                        } })))),
            React.createElement(Col, null,
                React.createElement("div", { className: "refresh-button", style: { marginTop: "25px" } }, isLoading === false ? React.createElement(FormButton, { onClick: function () { return getData(); }, text: "Refresh Data", theme: "blue" }) : React.createElement(SkeletonLoaderFakeBox, null)))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 12, className: "left-col" },
                React.createElement("strong", null, "API Call Metrics"),
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null, data !== null ? (React.createElement("div", { className: "", style: { height: "250px" } },
                        React.createElement(Chart, { options: {
                                chart: {
                                    id: "basic-bar",
                                    animations: {
                                        enabled: true,
                                        easing: "easeinout",
                                        speed: 800,
                                        animateGradually: {
                                            enabled: true,
                                            delay: 150
                                        },
                                        dynamicAnimation: {
                                            enabled: true,
                                            speed: 350
                                        }
                                    }
                                },
                                theme: {
                                    palette: "palette2" // upto palette10
                                },
                                tooltip: {
                                    x: {
                                        format: "mm:ss"
                                    }
                                },
                                stroke: {
                                    width: 1
                                },
                                xaxis: {
                                    type: "datetime"
                                }
                            }, series: data, type: "line", height: "250" }))) : null)))),
        React.createElement(Row, null,
            React.createElement(Col, { className: "right-col" },
                React.createElement("strong", null, "Throttled Call Metrics"),
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null, waitData !== null ? (React.createElement("div", { className: "", style: { height: "250px" } },
                        React.createElement(Chart, { options: {
                                chart: {
                                    id: "basic-bar2",
                                    animations: {
                                        enabled: true,
                                        easing: "easeinout",
                                        speed: 800,
                                        animateGradually: {
                                            enabled: true,
                                            delay: 150
                                        },
                                        dynamicAnimation: {
                                            enabled: true,
                                            speed: 350
                                        }
                                    }
                                },
                                theme: {
                                    palette: "palette2" // upto palette10
                                },
                                tooltip: {
                                    x: {
                                        format: "mm:ss"
                                    }
                                },
                                stroke: {
                                    width: 1
                                },
                                xaxis: {
                                    type: "datetime"
                                }
                            }, series: waitData, type: "line", height: "250" }))) : null))))));
});
export default OrchestryAdminToolApiThrottlingHours;
